import { FC, useMemo } from 'react';
import {
  Col, Divider, Row, Typography,
} from 'antd';
import { userInfoProfile } from '../../../api/profile';
import { formatRelativeDate } from '../../../utils/formatRelativeDate';

const RowTable: FC<{ label: string, value?: string }> = ({ label, value }) => (
  <Col span={6}>
    <Typography style={{ fontWeight: 'bold' }}>{label}</Typography>
    <Typography>{value || 'Sem Registro'}</Typography>
  </Col>
);

const sectionTitleStyle = {

};

export const TableUserInfo: FC<{ cardTitle: string, userEmail: string }> = ({
  cardTitle,
  userEmail,
}) => {
  const { data } = userInfoProfile(userEmail);

  const { firstAccess, lastAccess } = useMemo(() => {
    const accessData = {
      firstAccess: data?.infoProfile.firstAccess,
      lastAccess: data?.infoProfile.lastAccess,
    };

    return Object.entries(accessData).reduce((acc, [key, value]) => {
      acc[key] = value ? formatRelativeDate(new Date(value), true) : 'Sem registro';
      return acc;
    }, {} as Record<string, string>);
  }, [data?.infoProfile.firstAccess, data?.infoProfile.lastAccess]);

  return (
    <div
      style={{
        padding: '16px',
        borderRadius: '8px',
        background: '#fafafa',
      }}
    >
      <Typography
        style={{
          fontSize: '28px',
          fontWeight: 400,
          margin: '20px 0px',
        }}
      >
        {cardTitle}
      </Typography>
      <Row>
        <RowTable label="User id" value={data?.infoProfile.student} />
        <RowTable label="Email" value={data?.infoProfile.email} />
        <RowTable label="Primeira vez" value={firstAccess} />
        <RowTable label="Ultima vez" value={lastAccess} />
      </Row>
      <Divider />
      <Row>
        <RowTable label="Telefone" value={data?.infoProfile.phone} />
        <RowTable label="Pais" value="" />
        <RowTable label="Região" value="" />
        <RowTable label="Cidade" value="" />
      </Row>
      <Divider />
    </div>
  );
};
