import { useCallback } from 'react';
import {
  gql, QueryResult, QueryTuple, useLazyQuery, useMutation, useQuery,
} from '@apollo/client';
import { message } from 'antd';

export interface Course {
  _id: string;
  title: string;
}

export interface CourseWeekGoals {
  min: {
    theoreticalStudy: number;
    theoreticalReview: number;
    smartReview: number;
    exam: number;
  };
  max: {
    theoreticalStudy: number;
    theoreticalReview: number;
    smartReview: number;
    exam: number;
  };
}

export interface CourseLesson {
  prerequisites?: string[];
  avgKnowledgeLevel?: number;
  lessonId: string;
  phase: string;
  enableAfter: string;
  classification: string[];
  __typename?: string;
  isPreCourse?: boolean;
}

export type ExamDiscriminatorType = 'mock' | 'exam' | 'smart_exam';

export type ExamsObjectType = {
  _id: string,
  key?: string,
  startsAt: string | null,
  finishesAt: string | null,
  type: ExamDiscriminatorType,
  template: string | null,
}

export interface CourseManyInfo {
  _id: string;
  title: string;
  className: string;
  courseWeekGoals: CourseWeekGoals;
  exams: ExamsObjectType[];
  phases: {
    phase: string;
    startsAt: Date;
  }[];
  lessons: CourseLesson[];
  detailedClassificationTree: string;
  classificationTree: string;
  finishesAt?: Date;
}

interface CoursesManyOutput {
  coursesMany: Course[];
}

export interface CoursesManyInfoOutput {
  coursesManyInfo: CourseManyInfo[];
}

export interface CoursesManyInfoInput {
  ids: string[];
}

const COURSES_MANY = gql`
  query CoursesMany {
    coursesMany {
      _id
      title
    }
  }
`;

const COURSES_MANY_INFO = gql`
  query CoursesManyInfo($ids: [ObjectId!]!) {
    coursesManyInfo(ids: $ids) {
      _id
      title
      className
      exams {
        _id
        template
        finishesAt
        startsAt
        type
      }
      finishesAt
      detailedClassificationTree
      classificationTree
      courseWeekGoals {
        min {
          theoreticalStudy
          theoreticalReview
          smartReview
          exam
        }
        max {
          theoreticalStudy
          theoreticalReview
          smartReview
          exam
        }
      }
      lessons {
        lessonId
        prerequisites
        phase
        enableAfter
        classification
        isPreCourse
      }
    }
  }
`;

const COURSE_CONFIG_INFO = gql`
  query CourseConfigInfo (
    $courseId: ObjectId!,
  ) {
    courseConfigInfo (
      courseId: $courseId,
    ) {
      activities
      smartReviewMax {
        questions
        flashcards
      }
      trialMode
      courseTrialPeriod
      preCourseTrialPeriod
      preCourseStartsAt
      courseStartsAt
      courseFinishesAt
      isCourseEnabledForRegistration
      weekGoals {
        min {
          theoreticalStudy
          theoreticalReview
          smartReview
          exam
        }
        max {
          theoreticalStudy
          theoreticalReview
          smartReview
          exam
        }
      }
      saleStartsAt
      saleEndsAt
      achievementGoals {
        theoreticalStudy {
          bronze
          silver
          gold
        }
        theoreticalReview {
          bronze
          silver
          gold
        }
        smartReview {
          bronze
          silver
          gold
        }
        exam {
          bronze
          silver
          gold
        }
      }
    }
  }
`;

type TrophyGoalsType = {
  bronze: number,
  silver: number,
  gold: number,
}

type ActivitiesTrophyGoalsType = {
  theoreticalStudy: TrophyGoalsType,
  theoreticalReview: TrophyGoalsType,
  smartReview: TrophyGoalsType,
  exam: TrophyGoalsType,
}

type WeekGoalsByActivityType = {
  theoreticalStudy: number,
  theoreticalReview: number,
  smartReview: number,
  exam: number,
}

export type CourseConfigInfoOutput = {
  courseConfigInfo: CourseConfigInputType,
}

export type CourseConfigInfoInput = {
  courseId: string;
}

export type ExtraActivityType = 'Exam'
  | 'Flashcard'
  | 'MockExam'
  | 'Question'
  | 'SmartExam'
  | 'SmartReview'
  | 'TheoreticalReview'
  | 'TheoreticalStudy';

export type CourseConfigInputType = {
  courseId: string,
  achievementGoals?: ActivitiesTrophyGoalsType,
  activities?: ExtraActivityType[],
  smartReviewMax?: {
    questions: number,
    flashcards: number,
  },
  preCourseTrialPeriod?: number,
  courseTrialPeriod?: number,
  trialMode?: 'DYNAMIC' | 'FIXED' | null,
  weekGoals?: {
    min: WeekGoalsByActivityType,
    max: WeekGoalsByActivityType,
  }
  preCourseStartsAt?: string | null,
  courseStartsAt?: string | null,
  courseFinishesAt?: string | null,
  saleStartsAt?: string | null,
  saleEndsAt?: string | null,
  isCourseEnabledForRegistration?: boolean | null,
}
interface UpdateCourseConfigInput {
  input: CourseConfigInputType,
}

interface UpdateCourseConfigOutput {
  updateCourseConfig: boolean,
}

const UPDATE_COURSE_CONFIG = gql`
  mutation UpdateCourseConfig(
    $input: CourseConfigInputType!
  ) {
    updateCourseConfig (
      input: $input
    )
  }
`;

interface UpdateCourseInput {
  input: Partial<Omit<CourseManyInfo, 'lessons'>> & {
    lessons?: CourseLesson[] & {
      action?: 'add' | 'edit' | 'delete';
    };
  };
}

interface UpdateCourseOutput {
  updateCourse: boolean;
}

const UPDATE_COURSE = gql`
  mutation UpdateCourse(
    $input: CourseInfoInputType!,
    ) {
    updateCourse(input: $input)
  }
`;

export function useUpdateCourseConfig() {
  const [updateCourseConfig] = useMutation<
    UpdateCourseConfigOutput,
    UpdateCourseConfigInput
  >(UPDATE_COURSE_CONFIG, {
    onError: error => {
      throw new Error(error.message);
    },
  });

  const handleUpdateCourseConfig = useCallback(async (input: CourseConfigInputType) => {
    await updateCourseConfig({
      variables: {
        input,
      },
    });
  }, [updateCourseConfig]);

  return handleUpdateCourseConfig;
}

export function useCourseConfigInfo(courseId: string) {
  return useQuery<CourseConfigInfoOutput, CourseConfigInfoInput>(COURSE_CONFIG_INFO, {
    variables: {
      courseId,
    },
  });
}

export function useCoursesManyInfo(): QueryTuple<CoursesManyInfoOutput, CoursesManyInfoInput> {
  return useLazyQuery<CoursesManyInfoOutput, CoursesManyInfoInput>(COURSES_MANY_INFO);
}

export function useCoursesMany(): QueryResult<CoursesManyOutput> {
  return useQuery<CoursesManyOutput>(COURSES_MANY);
}

export function useUpdateCourse() {
  const [updateCourse] = useMutation<UpdateCourseOutput, UpdateCourseInput>(UPDATE_COURSE, {
    onError: error => {
      message.error(error.message);
    },
  });

  const handleUpdateCourse = useCallback(async ({ input }: UpdateCourseInput) => {
    await updateCourse({
      variables: {
        input,
      },
    });
  }, [updateCourse]);
  return handleUpdateCourse;
}

export const INFO_COURSES_BY_EMAIL = gql`
  query InfoCourseProfile($email: String!) {
    infoCourseProfile(email: $email) {
      name
      courseId
      status
      acquisitionDate
      expirationDate
    }
  }
`;

export interface InfoCoursesProfileObjectType {
  infoCourseProfile: {
    name: string;
    courseId: string;
    status: string;
    acquisitionDate: string;
    expirationDate?: string;
  }[]
}

export const useCoursesProfile = (
  email: string,
): QueryResult<InfoCoursesProfileObjectType> => {
  return useQuery<InfoCoursesProfileObjectType>(INFO_COURSES_BY_EMAIL, {
    variables: { email },
    onError: err => {
      message.error(err.message);
    },
  });
};

// const GET_ALL_MOCK_EXAM_TEMPLATES = gql`
//   query GetAllMockExamTemplates {
//     getAllMockExamTemplates {
//       title
//       template
//     }
//   }
// `;

// type GetAllMockExamTemplatesOutputType = {
//   getAllMockExamTemplates: {
//     title: string,
//     template: string,
//   }[],
// }

// export function useAllMockExamTemplates() {
//   const [mockExamTemplates] = useLazyQuery<GetAllMockExamTemplatesOutputType>(GET_ALL_MOCK_EXAM_TEMPLATES);

//   const getTemplateHashMap = useCallback(async () => {
//     const { data } = await mockExamTemplates();
//     const hashMap: Record<string, string> = {};
//     data?.getAllMockExamTemplates.forEach(x => {
//       hashMap[x.template] = x.title;
//     });
//     return hashMap;
//   }, [mockExamTemplates]);

//   return {
//     getTemplateHashMap,
//   };
// }
