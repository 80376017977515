import { Typography } from 'antd';
import { FC } from 'react';

export const CardOfCourse: FC<{ cardTitle: string, profile: string }> = ({
  cardTitle,
  profile,

}) => {
  return (
    <div
      style={{
        padding: '16px',
        borderRadius: '8px',
      }}
    >
      <Typography
        style={{ fontSize: '23px', fontWeight: 400, margin: '20px 0px' }}
      >
        {cardTitle}
      </Typography>
    </div>
  );
};
